import PageContents from '@/types/PageContents';
import CessysVideo from './Cessys-Video.html';
import Cessys from './Cessys.html';
import IntroText from './Decompression-Intro-Text.html';
import IntroVideo from './Decompression-Intro-Video.html';
import ILessysDelta from './iLessys-Delta.html';
import ILessysPro from './iLessys-Pro.html';
import ILessysVideo from './iLessys-Video.html';
import ILessys from './iLessys.html';
import TessysVideo from './Tessys-Video.html';
import Tessys from './Tessys.html';

// const distanceX = 7.5;

const videoModalSize = {
  height: 'calc(315px + 4rem)',
  width: 'calc(560px + 4rem)',
};

const pageContent: PageContents[] = [
  {
    button: {
      top: '34%',
      left: '11%',
      type: 'video',
      content: 'Intro',
      textAlign: 'left',
    },
    modal: {
      ...videoModalSize,
      content: IntroVideo,
    },
  },
  {
    button: {
      top: '34%',
      left: '15.5%',
      type: 'open',
      content: '',
    },
    modal: {
      content: IntroText,
    },
  },

  {
    button: {
      top: '38%',
      left: '41%',
      type: 'video',
      content: '',
    },
    modal: {
      ...videoModalSize,
      content: TessysVideo,
    },
  },
  {
    button: {
      top: '38%',
      left: '45.5%',
      type: 'open',
      content: '',
    },
    modal: {
      content: Tessys,
    },
  },

  {
    button: {
      top: '27%',
      left: '62%',
      type: 'video',
      content: '',
    },
    modal: {
      ...videoModalSize,
      content: CessysVideo,
    },
  },
  {
    button: {
      top: '27%',
      left: '66.5%',
      type: 'open',
      content: '',
    },
    modal: {
      content: Cessys,
    },
  },

  {
    button: {
      top: '29%',
      left: '80%',
      type: 'video',
      content: '',
    },
    modal: {
      ...videoModalSize,
      content: ILessysVideo,
    },
  },
  {
    button: {
      top: '29%',
      left: '84.5%',
      type: 'open',
      content: '',
    },
    modal: {
      content: ILessys,
    },
  },
  {
    button: {
      top: '41.4%',
      left: '84.5%',
      type: 'open',
      content: '',
    },
    modal: {
      content: ILessysDelta,
    },
  },
  {
    button: {
      top: '48%',
      left: '84%',
      type: 'open',
      content: '',
    },
    modal: {
      content: ILessysPro,
    },
  },
];

export default pageContent;
